import {
    GET_ALL_PACKS,
    GET_PRODUCTS_BY_CATEGORY_SLUG,
    GET_PRODUCTS_BY_BRAND_SLUG,
    GET_PRODUCT_DETAIL,
    GET_ALL_PRODUCTS,
} from "../constants/action-types";

const initialState = {
    packs: [],
    products: [],
    productDetail: {},
    currentPage: 1,
    totalPages: 1,
    productsByCategorySlug: [],
    productsByBrandSlug: [],
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: action.products,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_ALL_PACKS:
            return {
                ...state,
                packs: action.packs,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_PRODUCT_DETAIL:
            return {
                ...state,
                productDetail: action.productDetail
            };
        case GET_PRODUCTS_BY_CATEGORY_SLUG:
            return {
                ...state,
                productsByCategorySlug: action.productsByCategorySlug
            };
        case GET_PRODUCTS_BY_BRAND_SLUG:
            return {
                ...state,
                productsByBrandSlug: action.productsByBrandSlug
            };
        default:
            return state;
    }
};

export default productsReducer;