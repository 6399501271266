import { combineReducers } from 'redux';

// Import custom components
import compareReducer from './compare';
import modalReducer from './modal';
import categoryReducer from "./category";
import userReducer from "./user";
import productReducder from "./product";
import variantReducer from "./variant";
import variantFilterReducer from "./variantFilter";
import productFilterReducer from "./productFilter";
import cart from './cart';
import wishlistReducer from "./wishlistReducer";
import bannersReducer from "./banner";


const rootReducer = combineReducers( {
    compare: compareReducer,
    modal: modalReducer,
    cartlist: cart,
    categories: categoryReducer,
    user: userReducer,
    variants: variantReducer,
    variantfilters: variantFilterReducer,
    products: productReducder,
    productFilters: productFilterReducer,
    usercartlist: cart,
    userwishlist: wishlistReducer,
    banners: bannersReducer,
} );

export default rootReducer;