import {
    FILTER_PRODUCTS_BY_BRAND_SLUG,
    FILTER_PRODUCTS_BY_CATEGORY_SLUG,
    FILTER_PRODUCTS_BY_SEARCH_KEYWORD,
    RESET_PRODUCT_FILTERS,
    REFRESH_PRODUCT_FILTERS,
    SORT_PRODUCTS_ASC,
    SORT_PRODUCTS_BY_FIELD
} from '../constants/action-types'

const initialState = {
    sortField: "id",
    sortAsc: true,
    category: "",
    brand: "",
    search: ""
}

function productFilterReducer(state = initialState, action) {
    switch (action.type) {

        case FILTER_PRODUCTS_BY_BRAND_SLUG:
            return {
                ...state,
                brand: action.brand
            };

        case FILTER_PRODUCTS_BY_CATEGORY_SLUG:
            return {
                ...state,
                category: action.category
            };

        case FILTER_PRODUCTS_BY_SEARCH_KEYWORD:
            return {
                ...state,
                search: action.search
            };

        case SORT_PRODUCTS_ASC:
            return {
                ...state,
                sortAsc: action.sortAsc
            };

        case SORT_PRODUCTS_BY_FIELD:
            return {
                ...state,
                sortField: action.sortField
            };

        case RESET_PRODUCT_FILTERS:
            return {
                sortField: "id",
                sortAsc: true,
                category: "",
                brand: "",
                search: ""
            };

        case REFRESH_PRODUCT_FILTERS:
            return {state: initialState};

        default:
            return state;
    }
}

export default productFilterReducer;