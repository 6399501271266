import { GET_BANNERS } from "../constants/action-types";

const initialState = {
    banners: []
};

const bannerReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case GET_BANNERS:
            return {
                banners: action.banners
            };
        default:
            return state;
    }
};

export default bannerReducer;